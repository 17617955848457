import { Typography, Slide, FormControlLabel, Box, Switch } from "@mui/material"
import React, { useState, } from "react"


const SlideAnimation = ({text, textVisible, setTextVisible}) => {
 
    const handleChange = () => {
        setTextVisible((prev) => !prev)
      }

      return (
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden'
          }}
        >

          <Slide
            direction="left"
            in={textVisible}  
            timeout={650} //the speed of the
            mountOnEnter 
            unmountOnExit>
          <Box sx={{}}> {/* kuvaus teksti kahdessa osassa */}
                <Typography sx={{fontSize: {
                        xs: 20,
                        md: 20,
                        lg: 25,
                        lgplus: 25
                    }}}> 
                    {text}
                </Typography>
                </Box>
          </Slide>
        </Box>
      )
}


export default SlideAnimation