import axios from "axios"
const baseUrl = '/api/email'


const handleEmail = async (emailObject) =>{
    const request = await axios.post(baseUrl, emailObject)
    return request.data

}

export default{ handleEmail, }