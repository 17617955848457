import { Container, Box, Typography } from "@mui/material"
import { CssBaseline } from '@mui/material';
import TopBar from "./components/Header"
import HomeSection from "./components/Home"
import { createTheme, ThemeProvider } from '@mui/material'
import AboutMe from "./components/AboutMe"
import Contact from "./components/Contact"
import { useState, useEffect } from "react"

const App = () => {
  const screenWidth = window.innerWidth
  const [textVisible, setTextVisible] = useState(false)
  const [pages, setPages] = useState([
    {title : 'Home', idvalue: 'home', selected : true},
    {title : 'About Me', idvalue: 'aboutme',selected : false},
    {title : 'Contact', idvalue: 'contact', selected : false}
  ])
  const colors = { //määrittele toistuvat värit täällä ja passaa propseina
    'blue': '#008FFF',
    'carbon': '#1D1D1D',
    'green' : '#0EF21C',
    'red' : '#F30404',
    'white': '#FFFFFF'
  }
  const theme = createTheme({   //luo teeman jossa asetetaan typography fontfamily
    typography: {
      fontFamily: [
        'Roboto+Slab',    //ensi sijaiseksi fontiksi asetetaan Inter
        'Inter',  //fallback fontti(t), mikäli mikään fontti ei toimi käytetään default fontteja
      ].join(','),
    },
    breakpoints: {
      values: {     //set the screen size breakpoints based on width
        xs: 0,
        xsplus: 340,
        sm: 600,
        md: 900, 
        mdplus:1100,
        lg: 1400,
        lgplus: 1750,
        xl: 2000,
      },
    },})

    const goToPage = (page) =>{
        const element = document.getElementById(page.idvalue)
        element.scrollIntoView({behavior: "smooth"})
    }

    const refreshPages = (selectedPage) =>{
        const updatedPages = pages.map(page =>({
            ...page,
            selected: page.idvalue === selectedPage 
        })) //maps through the pages and sets selected true on the page/pages which title is equal to selectedPage
        setPages(updatedPages)
    }

    const getAbout = () => { //responsible for rendering the about me text at the correct time
       const options = {
          root: null,
          threshold: [0, 0.1, 0.2, 0.5, 0.6, 1] //array of thresholds on when to call the observer
       }

       const callback = (entries) => {
        entries.forEach((entry) =>{
            const ratio = entry.intersectionRatio
            if(ratio > 0.6  || ratio > 0.2 && screenWidth <= 700){  //if over this amount of the section is visible the text is shown
              setTextVisible(true)                                  //different radio for small screens
            }   

            if(ratio < 0.15){ // if less than this amount of the section is visible the text goes hidden
              setTextVisible(false)
            }
        })
     }

       const observer = new IntersectionObserver(callback, options)

       const target = document.getElementById('aboutme')
       if(target){
        observer.observe(target)
       }
    }
    

    const getCurrentPage = () => { 
      const sections = ['home', 'aboutme', 'contact']
    
      const options = {
        root: null, //asettaa tarkasteltavan viewportin, tässä tapauksessa selaimen viewport
        threshold: 0.8, // määrittää kuinka paljon osiota pitää olla näkyvissä että alla oleva callback kutsutaan
      }
    
      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const currentSection = entry.target.id      
            refreshPages(currentSection)
          }
        })
      }  
    
      const observer = new IntersectionObserver(callback, options)
    
      sections.forEach((section) => {
        const target = document.getElementById(section)
        if (target) {
          observer.observe(target)
        }
      })
    }
    
    useEffect(() => {
      getCurrentPage()
      getAbout()
    }, [])


  return (
    <div style={{minHeight: '100vh' }}> 
      <CssBaseline></CssBaseline> {/* nollaa valmiita css tyylejä yms */}

      <TopBar goToPage={goToPage} theme={theme} refreshPages={refreshPages} pages={pages}></TopBar>

      <div id="home" style={({backgroundColor: colors.carbon })}> 
        <HomeSection colors = {colors} theme={theme}></HomeSection>     
      </div>

      <div id="aboutme">
        <AboutMe colors = {colors} theme={theme} textVisible={textVisible}></AboutMe>
      </div>

      <div id="contact" style={({backgroundColor: colors.carbon })}>
        <Contact colors = {colors} theme={theme}></Contact>
      </div>

    </div>
  )
}

  export default App