import React, { useState, useEffect } from "react"

const TextAnimation = ({text}) => { //fade in fade out kind of effect
    const [visibleChars, setVisibleChars] = useState(0)
    const [isReversing, setIsReversing] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {          //set a pause inbetween
        if (!isReversing) {
            // If not reversing increase until the whole text is visible
            setVisibleChars((prev) => Math.min(prev + 1, text.length))
    
            // when the whole text is visible set reversing to true
            if (visibleChars === text.length) {
              clearInterval(interval)
              setTimeout(() => {
                setIsReversing(true)    //small break before reversing
              }, 2000)
            }
          } else {
            // when reverse is true decrease the characters one by one
            setVisibleChars((prev) => Math.max(prev - 1, 0))
    
            // switch directions when theres only the first character
            if (visibleChars <= 2) {
              setIsReversing(false)
            }
          }
    }, 50)

    // Clear the interval on component unmount to prevent memory leaks and so on
    return () => clearInterval(interval)
  }, [isReversing, text.length, visibleChars])

  return (
      `${text.slice(0, visibleChars)}`
  )
}



export default TextAnimation