import { Container, Box, Typography, ThemeProvider} from "@mui/material"
import React, { useState, useEffect } from 'react';
import "../styles.css"
import imageSrc from '../images/pic1.jpg'
import TextAnimation from "./TextAnimation"

const HomePage = ({theme, colors}) => {
    const screenWidth = window.innerWidth
    let descriptionText = ''
    let nameText = `Hello I'm Väinö'`
    if(screenWidth < 900){
      descriptionText = 'I make and design responsive websites and full stack web applications.' //tiivistää tekstin lyhyemmäksi että näyttää järkevämmältä puhelimella
    }else{
      descriptionText = 'I make and design responsive websites and full stack web applications. I graduated as a software developer from Etelä-Savon ammattiopisto in 17th of May 2023'
    }

    return(
      <ThemeProvider theme={theme}>
        <Box  //sisältää etusivun kaikki jutut
        sx = {{
          width :{
            xs: '75%',
            md: '85%',
            mdplus: '75%',
            lg: '65%',
            lgplus: '60%',
            xl: '50%',
          },         
          height : {
            xs: '800px',
            md: '900px',
            mdplus: '750px',
            lg: '900px',
            lgplus: '1080px',
          },
          margin : 'auto',
          border: '',
        }}
        >
        <Box    //divin sisällä etu osion tekstit ja kuvat
        sx={{
          display: 'flex',
          alignItems: 'center', 
          paddingTop : {xs: '17%', md : '25%', mdplus : '17%', lg : '25%'} // messy implement i know i know
        }}
      >
        <Box>   
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {   //keskittää otsikon pienillä näytöillä
              xs: 'center',  
              md: 'flex-start',
            },
          }}>
          <Typography sx={{
              fontSize : {
                xs: 22,
                xsplus: 33,
                sm: 45,
                md: 36,
                mdplus: 38,
                lg: 44,
                xl: 46
              },
              border: '',
              paddingTop: {
                xs: '60px', sm: '15px', md: '0' //lisää paddingia pienellä näytöllä että näyttää järkevältä
              },
              paddingBottom: {
                xs: '15px', md: '0'
              },
              fontWeight: '600',
              color : colors.blue,    //**CUSTOMIZE THE WAY THE TEXT BREAKS OFF ON DIFF WIDTHS */
          }}><TextAnimation text={`Hello I'm Väinö!`}></TextAnimation></Typography>  
          </Box>


          <Box    //a picture displayed only on small screens lol
           sx={{
            display:{xs: 'flex', md: 'none'}, 
            userSelect: 'none',
            justifyContent: 'center'    //center the image horizontally 
          }}>
            <Box sx={{
              width:{
                xs: '180px',
                xsplus: '240px'
              },
              height: '260px',  
            }}>
            <img
               src={imageSrc}
               alt="Väinö Ruhanen"
               style={{ 
                width: '100%',
               height: '100%',  
                borderRadius: '20%'    
              }}
            ></img>
            </Box>
          </Box>
          
      
          <Typography sx = {{ //
            width:{
              xs: '100%', //the breakpoints are in App.js
              md: 450,  
              mdplus: 550,
              lg: 700,  
              xl: 700
            }, 
            color: 'white',
            border: '',
            fontSize:{
              xs: 20,  
              xsplus: 24,
              sm: 30,  
              md: 18,  
              mdplus: 20,
              lg: 24, 
            },
            paddingTop:{
              xs: '25px', md: '0'
            },
            textAlign:{
              xs: 'center', md: 'left'
            }
          }}>{descriptionText}</Typography> 
        </Box>

        <Box
           sx={{
            border: '',
            display:{xs: 'none', md: 'flex'},
            width: '240px', 
            height: '260px',
            userSelect: 'none',
            marginLeft: 'auto' //positions the image to the far right of the box that contains the front page shit
        }}>
            <img
               src={imageSrc}
               alt="Väinö Ruhanen"
               style={{
                width: '100%',
                height: '100%',         
                borderRadius: '20%',
                marginTop: '23px' //creates margin on top of the image to sorta move it down to be on a good level with the text xd
              }}
            ></img>
          </Box>

          </Box>
        </Box> 
      </ThemeProvider>
    )
    
}

export default HomePage