import { Typography, Box, ThemeProvider } from "@mui/material"
import imageSrc from '../images/pic2.jpg'
import SlideAnimation from "./SlideAnimation"
import { useState } from "react"


const AboutMe = ({colors, theme, textVisible}) => {
    let desc1 = 'My name is Väinö. I am a software developer from Mikkeli, Finland. I enjoy building web apps and other kinds of projects.'
    let desc2 = 'I graduated as a software developer from Esedu in 17.5.2023. I have experience with making and designing full stack web apps, websites and other kinds of software. Some of the techniques I have worked with include but are not limited to JavaScript, React, NodeJS, MongoDB and Express for full stack development and Figma for the designing. I also have some game development experience with Unity and C#.'
    return(
        <ThemeProvider theme = {theme}>
        <Box sx={{
            border: '',
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            alignItems: {   
              xs: 'center',  
              md: 'flex-start',
            },
            paddingTop: {
                xs: '60px',
                sm: '70px',
                md: '100px',
                mdplus:'120px',
                lg: '140px',
                lgplus: '200px'
                },
            width: {
                sx:'100%',
                md: '95%',
                xl: '1920px'
                },
            height: {
                xs: '900px',
                sm: '650px',
                md: '650px',
                mdplus: '650px',
                lg: '850px',
                lgplus: '950px'
            }
        }}> {/* wraps all the content of the about me section*/}


            <Box sx={{  //image that is displayed on mdplus and above screens
                border: '',
                display: {xs: 'none', md: 'flex'},
                marginLeft:{
                    md: '8%',
                    mdplus: '9%',
                    lg: '11%',
                    lgplus: '240px'
                },
                 marginTop:{
                    md: '120px',
                     mdplus: '60px',
                     lg: '100px',
                     lgplus: '120px'
                    },
                width: {
                    md: '220px',
                    mdplus: '276px',
                    lg: '372px',
                    lgplus: '414px'
                }, 
                height: {
                    md: '166px',
                    mdplus: '208px',
                    lg: '280px',
                    lgplus: '312px'
                },
                userSelect: 'none'}}>
              <img 
                src={imageSrc}
                alt="Väinö Ruhanen"
                style={{
                 width: '100%',
                 height: '100%',   
                 borderRadius: '15%'    
               }}
              ></img>
            </Box>
            
            <Box sx={{
                border: '',
                marginLeft: {sx: 'none', md: 'auto'},
                marginTop: {xs:'0px', lg: '50px'},
                width: {
                    xs: '240px',
                    xsplus: '300px',
                    sm: '500px',
                    md: '55%',
                    mdplus: '55%',
                    lg: '50%',
                    lgplus: '50%'
                }
            }}>  {/* for the texts */}
                <Typography sx={{
                     textAlign: {   
                        xs: 'center',  
                        md: 'left',
                      },
                    color: colors.blue,
                    fontSize: {
                        xs: 40,
                        md: 40,
                        lg: 50,
                        lgplus: 50
                    },
                    fontWeight: '600'
                }}>About me</Typography>

                <Box sx={{paddingTop:{xs: '15px', lg: '20px'}}}> {/* kuvaus teksti kahdessa osassa */}
                    <SlideAnimation textVisible={textVisible} text={desc1}></SlideAnimation>
                    <br></br>
                    <SlideAnimation textVisible={textVisible} text={desc2}></SlideAnimation> 
                </Box>
            </Box>
        </Box>
        </ThemeProvider>
    )
}


export default AboutMe