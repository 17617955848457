import { Toolbar, AppBar, Link, Button, Box, Menu, MenuItem, ThemeProvider, Typography, IconButton, } from "@mui/material"
import React, { useState, useEffect } from 'react'
import MenuIcon from '@mui/icons-material/Menu'

const TopBar = ({theme, goToPage, pages, refreshPages}) =>{
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [language, setLanguage] = useState('en')  


    const handlePageSelect = (selectedPage) => { 
        goToPage(selectedPage)
    }

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = (language) => {
      setLanguage(language)
      setAnchorEl(null)
      handleCloseNavMenu() //this wouldnt need to be called on other than xs and sm screens but whatever
    }

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget)
    }

    const handleNavPageChange = (selectedPage) => {
      goToPage(selectedPage)
      handleCloseNavMenu()
    }

    const handleCloseNavMenu = () => {
      setAnchorElNav(null)
    }

    return(
      <ThemeProvider theme={theme}>
        <Box>
        <AppBar sx={{ bgcolor: "#1D1D1D", userSelect: 'none' }}>
            <Toolbar sx={{ justifyContent: "flex-end" }}>
            {pages.map((page) =>(   //maps through the pages and creates buttons on the top bar with each page's title
                <Button
                  sx={{
                    display:
                    {
                      xs: 'none', md: 'flex'
                    },}} //doesnt show the menu items on xs screen
                  key={page.title}
                  onClick={() => handlePageSelect(page)}
                ><Typography
                  sx = {{
                  color: page.selected ? '#008FFF' : 'white',  //shows the selected page as blue, rest as white
                  fontWeight: page.selected ? 'bold' : 'normal',
                  }}>{page.title}
                </Typography> 
                </Button> 
            ))}
            {/*
                <Button
                  sx={{
                    color: 'white',
                    fontWeight: 'normal',
                    display: {xs: 'none', md: 'flex'}
                  }}
                  onClick={handleMenu}    //sets below Menu visible kinda lol
                ><Typography>Language</Typography>
                </Button>
            */ } 

            <Menu
             anchorEl={anchorEl}    
             open={Boolean(anchorEl)}
             onClose={handleClose}  
            > 
                <MenuItem onClick={() => handleClose('en')}>English</MenuItem>         
                <MenuItem onClick={() => handleClose('fi')}>Suomi</MenuItem>
            </Menu>


            { /* BELOW FOR PHONES */}

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => handleNavPageChange(page)} sx={{color: 'black'}}>
                  <Typography>{page.title}</Typography>
                </MenuItem>
              ))}
            {//  <MenuItem onClick={handleMenu} key={'placeholder'} sx={{paddingTop: '0', color: 'black'}}>    
              //   <Typography>Language</Typography>
               // </MenuItem>
            }   
            </Menu>
          </Box>

            </Toolbar>
        </AppBar>m 
        </Box>
      </ThemeProvider>
    )
}


export default TopBar