import { Box, ThemeProvider, Typography, TextField, Button } from "@mui/material"
import { useState } from "react"
import emailService from "../services/email"

const Contact = ({colors, theme}) =>{
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const formWidth = window.innerWidth >= 900 ? '50%' : '60%'
    const marginMobile = '30px'
    
    const [namecolor, setNamecolor] = useState(colors.blue)
    const [emailcolor, setEmailcolor] = useState(colors.blue)
    const [messagecolor, setMessagecolor] = useState(colors.blue)

    const handleSubmit = (event) =>{
        event.preventDefault()
        const messageArray = {
            'user_name': name,
            'user_email': email,
            'message': message
        }

        if(validateName() && validateEmail() && validateMessage()){
            emailService.handleEmail(messageArray) //lähettää backendille pyynnön jossa on lähetettävän sähköpostin tiedot
            clearForm()
            window.alert('Message sent, thank you :)')
        }
    }

    const clearForm = () =>{
        setName('')
        setEmail('')
        setMessage('')
    }

    const setErrorColor = (setColor, color, revertColor, timeout) => {
        setColor(color)
        setTimeout(() => {
          setColor(revertColor)
        }, timeout)
      }

    const validateName = () =>{
        if(name === '' || name.length < 3){
            setErrorColor(setNamecolor, colors.red, colors.blue, 3000)
            return false
        }
        return true
    }

    const validateEmail = () =>{
        if(email === '' || !email.match(/.+\@.+\..+/)){
            setErrorColor(setEmailcolor, colors.red, colors.blue, 3000)
            return false
        }
        return true
    }

    const validateMessage = () =>{
        if(message === '' || message.length < 8){
            setErrorColor(setMessagecolor, colors.red, colors.blue, 3000)
            return false
        }
        return true
    }


    return(
        <ThemeProvider theme={theme}>
        <Box sx={{
            height: {xs: '700px', lg: '900px'},
            border: ''
            }}> {/* TEMPORARY*/}
            <Box sx={{
                border: '',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: {xs: '85px', lg: '125px'}
            }}>
                
            <Typography sx={{
                color: colors.blue,
                fontWeight: '600',
                fontSize: 50
            }}>Contact me</Typography>
            </Box>

            <form onSubmit={handleSubmit}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '',
                    marginTop: {xs: '40px', lg: '65px'}
                    }}>
                    <Box sx={{
                        display: 'flex',  
                        flexDirection: {xs: 'column', md: 'row'}, 
                        border: '',
                        justifyContent: 'space-between',
                        width: formWidth}}>
                    <TextField
                        placeholder="Your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                        width: {xs:'100%', md:'45%'},
                        backgroundColor: 'white',
                        border: '1px solid',
                        borderColor: namecolor,
                        marginBottom: {xs: marginMobile, md: '0px'}
                        }}
                    ></TextField>

                    <TextField 
                        placeholder="Your Email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                        width: {xs:'100%', md:'45%'},
                        backgroundColor: 'white',
                        border: '1px solid',
                        borderColor: emailcolor,
                        marginBottom: {xs: marginMobile, md: '0px'}
                        }}
                    ></TextField>
                    </Box>  

                <TextField 
                    placeholder="Your message here"
                    multiline={true} //the multiline + rows is a very bs way to increase height
                    rows={7}   //change this based on screen size     
                    value={message}     
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{
                    width: formWidth,
                    backgroundColor:'white',
                    marginTop: {xs: '0px', md: '60px'},
                    border: '1px solid',
                    borderColor: messagecolor,
                    marginBottom: {xs: marginMobile, md: '0px'}
                    }}
                ></TextField>

                <Button type="submit"
                sx={{
                    backgroundColor: colors.blue,
                    color: colors.white,
                    borderRadius: '10%',
                    marginTop: {xs: '0px', md: '15px'},
                    '&:hover': {
                        backgroundColor: colors.green,
                      },
                }}>Submit</Button>
                </Box>
            </form>
        </Box>
        </ThemeProvider>
    )
}


export default Contact